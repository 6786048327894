<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-key"></i> Usuário</h5>
                    <btn-refresh @click="obterUsuario()"></btn-refresh>
                </div>
                <div class="p-mb-4">
                    <detalhe titulo="Tipo">{{ usuario?.tipoDescr }}</detalhe>
                    <detalhe titulo="Pessoa">{{ usuario?.tipoPessoaDescr }}</detalhe>
                    <detalhe titulo="Nome">{{ usuario?.nome }}</detalhe>
                    <detalhe titulo="Login">{{ usuario?.login }}</detalhe>
                    <detalhe titulo="Email">{{ usuario?.email }}</detalhe>
                    <detalhe titulo="Telefone">{{ $toTel(usuario?.telefone) }}</detalhe>
                    <detalhe titulo="Status">
                        <status :status="usuario?.statusDescr"></status>
                    </detalhe>
                </div>
                <btn-inserir></btn-inserir>
                <btn-atualizar :usuario="usuario"></btn-atualizar>
                <btn-ativar-desativar :usuario="usuario" @atualizar="usuario = $event"></btn-ativar-desativar>
                <btn-voltar :route="{ name: 'Usuarios' }"></btn-voltar>
            </div>
        </div>
    </div>
    <perfis></perfis>
    <projetos></projetos>
</template>

<script>
import UsuariosServices from './services';
import BtnInserir from './BtnInserir';
import BtnAtualizar from './BtnAtualizar';
import Perfis from './Perfis';
import BtnAtivarDesativar from './BtnAtivarDesativar';
import Projetos from './Projetos';

export default {
    components: {
        BtnInserir,
        BtnAtualizar,
        Perfis,
        BtnAtivarDesativar,
        Projetos,
    },

    data() {
        return {
            usuario: null,
        };
    },

    methods: {
        obterUsuario() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterPorId(this.$route.params.id).then(response => {
                if (response && response.success) {
                    this.usuario = response.data;
                } else {
                    this.usuario = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        voltar() {
            this.$router.push({ name: 'Usuarios' });
        },
    },

    mounted() {
        this.obterUsuario();
    },
};
</script>
