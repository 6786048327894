import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_ADMINISTRACAO_PORT}${process.env.VUE_APP_API_PATH}`;

export default {
    obterTodos() {
        return axiosJwt.get(`${api}/controleacesso/usuarios`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/controleacesso/usuarios/${id}`);
    },

    obterPerfis(id) {
        return axiosJwt.get(`${api}/controleacesso/usuarios/${id}/perfis`);
    },

    inserir(usuario) {
        return axiosJwt.post(`${api}/controleacesso/usuarios`, usuario);
    },

    obterPorCpf(cpf) {
        return axiosJwt.get(`${api}/controleacesso/usuarios/cpf/${cpf}`);
    },

    atualizar(usuario) {
        return axiosJwt.put(`${api}/controleacesso/usuarios`, usuario);
    },

    associar(perfis) {
        return axiosJwt.post(`${api}/controleacesso/usuarios/associar`, perfis);
    },

    desassociar(perfis) {
        return axiosJwt.post(`${api}/controleacesso/usuarios/desassociar`, perfis);
    },

    ativar(usuarioId) {
        return axiosJwt.patch(`${api}/controleacesso/usuarios/${usuarioId}/ativar`);
    },

    desativar(usuarioId) {
        return axiosJwt.patch(`${api}/controleacesso/usuarios/${usuarioId}/desativar`);
    },

    obterProjetos(usuarioId) {
        return axiosJwt.get(`${api}/controleacesso/usuarios/${usuarioId}/projetos`);
    },

    associarProjetos(projetos) {
        return axiosJwt.post(`${api}/controleacesso/usuarios/projetos/associar`, projetos);
    },

    desassociarProjetos(projetos) {
        return axiosJwt.post(`${api}/controleacesso/usuarios/projetos/desassociar`, projetos);
    },
};
