<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <div class="p-d-flex">
                    <h5><i class="pi pi-fw pi-sliders-h"></i> Projetos</h5>
                    <btn-refresh @click="obterProjetos()"></btn-refresh>
                </div>
                <Accordion :multiple="true" :activeIndex="0">
                    <AccordionTab header="Associados">
                        <projetos-associados
                            :projetos="projetosAssociados"
                            @selecionar="projetosAssociadosSelecionados = $event"
                            @desassociar="desassociar()"
                        ></projetos-associados>
                    </AccordionTab>
                    <AccordionTab header="Disponíveis">
                        <projetos-disponiveis
                            :projetos="projetosDisponiveis"
                            @selecionar="projetosDisponiveisSelecionados = $event"
                            @associar="associar()"
                        ></projetos-disponiveis>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    </div>
</template>

<script>
import ProjetosAssociados from './ProjetosAssociados';
import ProjetosDisponiveis from './ProjetosDisponiveis';
import UsuariosServices from './services';

export default {
    components: {
        ProjetosAssociados,
        ProjetosDisponiveis,
    },

    // emits: ['atualizar-tem-regras-associadas'],

    data() {
        return {
            projetosAssociados: [],
            projetosAssociadosSelecionados: [],
            projetosDisponiveis: [],
            projetosDisponiveisSelecionados: [],
        };
    },

    methods: {
        obterProjetos() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterProjetos(this.$route.params.id).then(response => {
                if (response && response.success) {
                    this.projetosAssociados = response.data.projetosAssociados;
                    this.projetosDisponiveis = response.data.projetosDisponiveis;
                } else {
                    this.projetosAssociados = [];
                    this.projetosDisponiveis = [];
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: 'Erro ao obter projetos',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        desassociar() {
            this.$store.dispatch('addRequest');
            let projetos = {
                usuarioId: this.$route.params.id,
                projetos: this.projetosAssociadosSelecionados,
            };
            UsuariosServices.desassociarProjetos(projetos).then(response => {
                if (response && response.success) {
                    this.projetosAssociados = response.data.projetosAssociados;
                    this.projetosDisponiveis = response.data.projetosDisponiveis;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Projetos desassociados com sucesso',
                        life: 3000,
                    });
                } else {
                    this.obterProjetos();
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Usuário',
                        detail: 'Erro ao desassociar projetos',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },

        associar() {
            this.$store.dispatch('addRequest');
            let projetos = {
                usuarioId: this.$route.params.id,
                projetos: this.projetosDisponiveisSelecionados,
            };
            UsuariosServices.associarProjetos(projetos).then(response => {
                if (response && response.success) {
                    this.projetosAssociados = response.data.projetosAssociados;
                    this.projetosDisponiveis = response.data.projetosDisponiveis;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuários',
                        detail: 'Projetos associados com sucesso',
                        life: 3000,
                    });
                } else {
                    this.obterProjetos();
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Usuários',
                        detail: 'Erro ao associar projetos',
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },

    mounted() {
        this.obterProjetos();
    },
};
</script>
