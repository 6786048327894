<template>
    <tabela :data="projetos" dataKey="projetoId" :globalFilterFields="['cnpj', 'nome']">
        <template #botoes>
            <Button
                v-if="$temAcessoView('CTRLACS-USR-07')"
                label="Associar Selecionados"
                class="p-ml-2"
                icon="pi pi-link"
                @click="$emit('associar')"
                :disabled="projetosSelecionados.length < 1"
            />
        </template>
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <Checkbox name="projetos" :value="slotProps.data.projetoId" v-model="projetosSelecionados" />
                </template>
            </Column>
            <Column field="cnpj" header="CNPJ" :sortable="true">
                <template #body="slotProps">
                    <span>{{ $toCnpj(slotProps.data.cnpj) }}</span>
                </template>
            </Column>
            <Column field="nome" header="Projeto" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.nome }}
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
export default {
    props: {
        projetos: {
            type: Array,
        },
    },

    emits: ['selecionar', 'associar'],

    data() {
        return {
            projetosSelecionados: [],
        };
    },

    watch: {
        projetosSelecionados() {
            this.$emit('selecionar', this.projetosSelecionados);
        },

        projetos() {
            this.projetosSelecionados = [];
        },
    },
};
</script>
