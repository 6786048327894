<template>
    <tabela :data="perfis" dataKey="perfilAcessoId" :globalFilterFields="['codPerfilAcesso', 'descricaoPerfilAcesso']">
        <template #botoes>
            <Button
                v-if="$temAcessoView('CTRLACS-USR-06')"
                label="Desssociar Selecionados"
                class="p-button-warning p-ml-2"
                icon="pi pi-link"
                @click="$emit('desassociar')"
                :disabled="perfisSelecionados.length < 1"
            />
        </template>
        <template #conteudo>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <Checkbox name="regras" :value="slotProps.data.perfilAcessoId" v-model="perfisSelecionados" />
                </template>
            </Column>
            <Column field="codRegraAcesso" header="Código" :sortable="true">
                <template #body="slotProps">
                    <span>{{ slotProps.data.codPerfilAcesso }}</span>
                </template>
            </Column>
            <Column field="descricaoRegraAcesso" header="Descrição" :sortable="true">
                <template #body="slotProps">
                    {{ slotProps.data.descricaoPerfilAcesso }}
                </template>
            </Column>
        </template>
    </tabela>
</template>

<script>
export default {
    props: {
        perfis: {
            type: Array,
        },
    },

    emits: ['selecionar', 'desassociar'],

    data() {
        return {
            perfisSelecionados: [],
        };
    },

    watch: {
        perfisSelecionados() {
            this.$emit('selecionar', this.perfisSelecionados);
        },

        perfis() {
            this.perfisSelecionados = [];
        },
    },
};
</script>
