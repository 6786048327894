<template>
    <Button
        label="Atualizar"
        icon="pi pi-pencil"
        class="p-ml-2"
        @click="toAtualizar()"
        v-if="$temAcessoView('CTRLACS-USR-03')"
    />
</template>

<script>
export default {
    props: {
        usuario: {
            type: Object,
        },
    },

    methods: {
        toAtualizar() {
            this.$router.push({
                name: 'Usuarios_Atualizar',
                params: {
                    id: this.usuario.usuarioId,
                },
            });
        },
    },
};
</script>
